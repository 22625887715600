<div class="matrix-operation-permission-popup-container">
	<div class="header-container">
		<div class="title">
			{{ 'MODALS.PERMISSIONS.MODAL_TITLE' | translate }}
		</div>
		<div class="close-container">
			<button class="close" (click)="dialogRef.close()"></button>
		</div>
	</div>
	<div class="list-container">
		<mat-tab-group (selectedIndexChange)="forceDetection($event)">
			<mat-tab label="{{ 'MODALS.PERMISSIONS.APPLICATION_GROUP' | translate }}">
				@if (tabIndex === 0) {
					<addiction-list
						[data]="dataTableGroup"
						[isLoading]="isLoading"
						[pageSize]="10"
						[tableHeaderTemplate]="tableHeaderTemplate"
						[rowTemplate]="rowTemplate"
						[noItemsTemplate]="noItemsTemplate"></addiction-list>
				}
			</mat-tab>
			<mat-tab label="{{ 'MODALS.PERMISSIONS.SMART_GROUP' | translate }}">
				@if (tabIndex === 1) {
					<addiction-list
						[data]="dataTableSmartGroup"
						[isLoading]="isLoading"
						[pageSize]="10"
						[tableHeaderTemplate]="tableHeaderTemplate"
						[rowTemplate]="rowTemplate"
						[noItemsTemplate]="noItemsTemplate"></addiction-list>
				}
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="buttons-container">
		<button class="confirm" (click)="confirm()">
			<span>{{ 'CONFIRM' | translate }}</span>
		</button>
	</div>
</div>

<ng-template #tableHeaderTemplate>
	<div class="single-row-header">
		<div class="single-column double first-name">
			<span>{{ 'MODALS.PERMISSIONS.NAME' | translate }}</span>
		</div>
		<div class="single-column single" *ngFor="let column of possiblePermission$ | async">
			<span>{{ column.label | toLocalizedValuePipe }}</span>
		</div>
	</div>
</ng-template>

<ng-template #rowTemplate let-group="item">
	<div class="single-row" *ngIf="group">
		<div class="single-column double first-name">
			<span class="highlight">{{ group.groupName }}</span>
		</div>
		<div class="single-column single checkbox" *ngFor="let op of possiblePermission$ | async">
			<mat-checkbox
				(change)="group.permissions[op.key] = $event.checked"
				class="checkbox"
				[checked]="group.permissions[op.key] === true"
				[indeterminate]="group.permissions[op.key] === 'indeterminate'">
			</mat-checkbox>
		</div>
	</div>
</ng-template>

<ng-template #noItemsTemplate>
	@if (!isLoading) {
		<div class="no-results-container">
			<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
			<div class="title">
				{{ 'GRID.NO_RESULTS_TITLE' | translate }}
			</div>
			<div class="text">
				{{ 'GRID.NO_RESULTS_TEXT' | translate }}
			</div>
		</div>
	}
</ng-template>
