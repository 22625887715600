import { createReducer, on } from '@ngrx/store';
import { StructureType } from 'addiction-components';
import produce from 'immer';
import { StructuresActions } from '../../../core/state/app.actions';
import { STATE_STATUS } from '../../models';
import { StructuresState } from './structures.state';

export const TAG = 'structures';

const initialState: StructuresState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	structureType: StructureType.PRODUCT,
	pages: [],
	data: { totalStructuresCount: 0, structures: [] },
	currentStructureField: undefined,
	paths: [],
	cachedPages: [],
	sort: { active: 'updateDate', direction: 'desc' },
	isDragging: false,
	containersCoordinates: new Map<number, string>(),
	lastIndex: 0,
	isSubStructure: false,
	desktopRatioAndUuid: { desktopRatio: 0, uuid: '' },
};

export const reducers = createReducer(
	initialState,
	on(StructuresActions.setStructures, (state, { structureType }) =>
		produce(state, (draft) => {
			if (structureType) draft.structureType = structureType;
		}),
	),
	on(StructuresActions.setSort, (state: StructuresState, { sort }) => ({
		...state,
		sort,
	})),
	on(StructuresActions.setPages, (state: StructuresState, { pages }) => ({
		...state,
		pages,
	})),
	on(StructuresActions.setStructuresSuccess, (state: StructuresState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				structures: data.structures,
				totalStructuresCount: data.totalStructuresCount,
			},
			pages: data.pages,
		};
	}),
	on(StructuresActions.setStructuresError, (state: StructuresState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(StructuresActions.setStatus, (state: StructuresState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(StructuresActions.setGridSearch, (state: StructuresState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(StructuresActions.setDeleteStructures, (state: StructuresState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(StructuresActions.setDeleteStructuresSuccess, (state: StructuresState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(StructuresActions.setDeleteStructuresError, (state: StructuresState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(StructuresActions.setCurrentStructureField, (state: StructuresState, { structureField }) => {
		return {
			...state,
			currentStructureField: structureField,
		};
	}),
	on(StructuresActions.setPath, (state: StructuresState, { uuid, path }) => {
		const paths = [...state.paths];
		const savedItemIndex = paths.findIndex((x) => x.uuid === uuid);
		if (savedItemIndex !== -1) {
			const updatedItem = { ...paths[savedItemIndex], path: path };
			paths[savedItemIndex] = updatedItem;
		} else {
			paths.push({ uuid: uuid, path: path });
		}
		return {
			...state,
			paths: paths,
		};
	}),
	on(StructuresActions.setCachedPages, (state: StructuresState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(StructuresActions.resetCache, (state: StructuresState) => ({
		...state,
		status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalStructuresCount: 0, structures: [] },
	})),
	on(StructuresActions.setIsDragging, (state: StructuresState, { isDragging }) => ({
		...state,
		isDragging,
	})),
	on(StructuresActions.setDraggingUUID, (state: StructuresState, { draggingUUID }) => ({
		...state,
		draggingUUID,
	})),
	on(StructuresActions.setContainersCoordinates, (state: StructuresState, { containerUUID, containerY }) => ({
		...state,
		containersCoordinates: new Map(state.containersCoordinates).set(containerY, containerUUID),
	})),
	on(StructuresActions.setContainerUUIDToExpand, (state: StructuresState, { containerUUID }) => ({
		...state,
		containerUUIDToExpand: containerUUID,
	})),
	on(StructuresActions.setLastIndex, (state: StructuresState, { lastIndex }) => ({
		...state,
		lastIndex,
	})),
	on(StructuresActions.setIsSubStructure, (state: StructuresState, { isSubStructure }) => ({
		...state,
		isSubStructure,
	})),
	on(StructuresActions.setDesktopRatioAndUuid, (state: StructuresState, { desktopRatioAndUuid }) => {
		return {
			...state,
			desktopRatioAndUuid: { desktopRatio: desktopRatioAndUuid.desktopRatio, uuid: desktopRatioAndUuid.uuid },
		};
	}),
);
