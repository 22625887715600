<div (click)="changeLang(locale)" *ngFor="let locale of compiledLocales" class="single-language" [class.active]="locale === selectedLocale">
	<img class="language" src="/assets/images/languages/{{ locale }}.png" />
	<label [for]="locale">{{ 'LANGUAGES.' + locale | translate }}</label>
	<button *ngIf="(defaultLocale$ | async) !== locale && showRemoveButton" (click)="deleteLang(locale)" class="remove"></button>
</div>
<div *ngIf="getPossibleLocales()?.length" class="locale-menu">
	<button *ngIf="showPlusButton" mat-icon-button [matMenuTriggerFor]="menu" class="add"></button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item *ngFor="let locale of getPossibleLocales()" (click)="addLocale(locale)" class="{{ locale }} with-icon">
			<span>{{ 'LANGUAGES.' + locale | translate }}</span>
		</button>
	</mat-menu>
</div>
