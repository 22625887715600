<form *ngrxLet="{ dataValue: dataContainerValue$, hotspotFields: hotspotFields } as viewModel" class="form-group-container" [formGroup]="formGroup">
	<div class="left-container">
		<datalean-structure-file-selector
			*ngIf="field"
			mode="compact"
			[inputTypeRequired]="'image'"
			[readonly]="!!field.readOnly"
			formatRequired="tiff#not"
			[disabled]="disabled"
			formControlName="image"
			[label]="field.label | toLocalizedValuePipe">
		</datalean-structure-file-selector>

		<div class="hotspot-image-container" *ngIf="field && viewModel.dataValue && formGroup.value?.['image']?.url">
			<img
				#mapImage
				(click)="createHotspot($event, field)"
				[src]="formGroup.value?.['image']?.url"
				style="max-width: 200px"
				(load)="imageLoaded = true" />
			@if (imageLoaded) {
				<datalean-hotspot
					*ngFor="let hotspot of viewModel.dataValue; let i = index; trackBy: trackHotspot"
					classBboundaryContainer=".hotspot-image-container"
					[coordinate]="{ x: hotspot?.[hotspotPositionX], y: hotspot?.[hotspotPositionY] }"
					[backgorundRect]="{ width: mapImage.width, height: mapImage.height }"
					label="{{ i + 1 }}"
					(deleteHotspot)="deleteHotspot(i)"
					(newValueEmitted)="movedHotspot(i, $event)"
					 >
				</datalean-hotspot>
			}
		</div>
	</div>

	<!-- PER MOTIVI DI CHANGE DETECTION NON USARE DIRETTAMENTE LA FUNZIONE getStructureFielsFroHotspot PER AVERE GLI STRUCTURE FIELDS -->
	<div class="right-container" [ngClass]="formGroup.value?.['image']?.url ? '' : 'hidden'">
		<datalean-container [structureFields]="viewModel.hotspotFields"  [readonly]="!!field?.readOnly || disabled" [defaultValues]="dataset"> </datalean-container>
	</div>
</form>
