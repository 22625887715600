<!-- HEADER SOLO PER COLLECTION DETAIL -->
<addiction-dam-header
	[showBackButton]="true"
	[showSearchField]="true"
	[showSizeSlider]="viewMode === 'grid'"
	[showViewModeButtons]="true"
	[showSortField]="true"
	[sortOptions]="sortOptions"
	[(viewMode)]="viewMode"
	[(sliderValue)]="sliderValue"
	(search)="service.setSearchValue($event)"
	(backButtonClick)="back.emit()"
	(sortChange)="service.setSortOption($event)"></addiction-dam-header>
<!-- FINE HEADER COLLECTION DETAIL -->

<div class="main-container">
	<div class="page-content">
		<addiction-grid
			*ngIf="viewMode === 'grid'"
			[columns]="10 - sliderValue"
			[data]="gridData()"
			[pageSize]="gridData().totalItems"
			[headerTemplate]="header"
			[cardTemplate]="cardTemplate"
			[noItemsTemplate]="noItemsTemplate"
			(cardClick)="onCardClick($event)"></addiction-grid>
		<addiction-list
			*ngIf="viewMode === 'list'"
			[data]="gridData()"
			[pageSize]="gridData().totalItems"
			[headerTemplate]="header"
			[tableHeaderTemplate]="tableHeaderTemplate"
			[rowTemplate]="rowTemplate"
			[noItemsTemplate]="noItemsTemplate"
			(rowClick)="onCardClick($event)"></addiction-list>

		<ng-template #cardTemplate let-item="item">
			<ng-container>
				<div class="card" *ngIf="item && service.status() !== STATE_STATUS.LOADING">
					<div class="image-container">
						<img [src]="item.preview?.url ?? (item.extension | toIcon)" />
						<mat-checkbox
							class="checkbox"
							[class.mat-checkbox-disabled]="false"
							[disabled]="true"
							[checked]="service.selectedElements().has(item.uuid)"
							(click)="$event.stopPropagation()"></mat-checkbox>
						<span class="format">{{ item.format | toFormat }}</span>
					</div>
					<div class="description-container">
						<span class="name">{{ item.name }}</span>
					</div>
				</div>
				<div class="card loading" *ngIf="!item || service.status() === STATE_STATUS.LOADING">
					<div class="image-container"></div>
					<div class="description-container">
						<span class="name"></span>
					</div></div
			></ng-container>
		</ng-template>

		<ng-template #header>
			<div class="header-grid-container" *ngIf="service.collection() as collection">
				<div class="header-grid">
					<div class="left-container">
						<div class="title">
							<h1>{{ collection.name }}</h1>
						</div>
					</div>
					<div class="right-container">
						<div class="elements">
							<span class="number">{{ collection.assets?.length }}</span>
							<span class="text">{{ 'GRID.ELEMENTS' | translate }}</span>
						</div>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #tableHeaderTemplate>
			<div class="single-row-header">
				<div class="single-column checkbox">
					<span class="checkbox"></span>
				</div>
				<div class="single-column image">
					<span class="image"></span>
				</div>
				<div class="single-column double title">
					<span class="highlight">{{ 'LIST.NAME' | translate }}</span>
				</div>
				<div class="single-column single format">
					<span class="format">{{ 'LIST.FORMAT' | translate }}</span>
				</div>
				<div class="single-column double tag">
					<span>{{ 'LIST.TAG' | translate }}</span>
				</div>
				<div class="single-column single created">
					<span>{{ 'LIST.CREATED' | translate }}</span>
				</div>
				<div class="single-column single modified">
					<span>{{ 'LIST.MODIFIED' | translate }}</span>
				</div>
				<div class="single-column arrow">
					<span class="arrow"></span>
				</div>
			</div>
		</ng-template>

		<ng-template #rowTemplate let-item="item">
			<div class="single-row" *ngIf="item">
				<mat-checkbox
					class="checkbox"
					[class.mat-checkbox-disabled]="false"
					[disabled]="true"
					[checked]="service.selectedElements().has(item.uuid)"
					(click)="$event.stopPropagation()">
				</mat-checkbox>
				<div class="single-column image">
					<img [src]="item.preview?.url ?? item.url" />
				</div>
				<div class="single-column double title">
					<span class="highlight">{{ item.name }}</span>
				</div>
				<div class="single-column single format">
					<span class="format">{{ item.format | toFormat }}</span>
				</div>
				<div class="single-column double tag">
					<span class="tag">
						<ng-container *ngFor="let tag of item.tags; let last = last">
							<span class="single-tag">{{ tag.name }}</span>
							<ng-container *ngIf="!last">, </ng-container>
						</ng-container>
					</span>
				</div>
				<div class="single-column single created">
					<span>{{ item.createDate | date : 'dd/MM/yyyy @ HH:mm' }}</span>
				</div>
				<div class="single-column single modified">
					<span>{{ item.updateDate | date : 'dd/MM/yyyy @ HH:mm' }}</span>
				</div>
				<div class="single-column arrow">
					<span class="arrow"></span>
				</div>
			</div>

			<div class="single-row-skeleton" *ngIf="!item">
				<div class="single-column checkbox"></div>
				<div class="single-column image"></div>
				<div class="single-column double title">
					<span class="highlight"></span>
				</div>
				<div class="single-column single format">
					<span class="format"></span>
				</div>
				<div class="single-column double tag">
					<span></span>
				</div>
				<div class="single-column single created">
					<span></span>
				</div>
				<div class="single-column single modified">
					<span></span>
				</div>
				<div class="single-column arrow"></div>
			</div>
		</ng-template>

		<ng-template #noItemsTemplate>
			<div class="no-results-container" *ngIf="service.status() !== STATE_STATUS.LOADING">
				<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
				<div class="title">
					{{ 'COLLECTION_DETAIL.NO_RESULTS_TITLE' | translate }}
				</div>
				<div class="text">
					{{ 'COLLECTION_DETAIL.NO_RESULTS_TEXT' | translate }}
				</div>
			</div>
		</ng-template>
	</div>
</div>
