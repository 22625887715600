import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Limit } from 'addiction-components';
import { BehaviorSubject, first, Observable } from 'rxjs';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-chips-selector',
	template: '',
	styleUrls: [],
})
/** NON utilizzare direttamente quest component (d'altronde è abstract...) */
export abstract class BaseChipsSelectorComponent implements ControlValueAccessor, Validator {
	protected disabled: boolean = false;
	protected skeletonLoaderChipsCounter = new BehaviorSubject<number>(0);
	protected items = new BehaviorSubject<ChipValue[]>([]);

	//variabile aggiunta per sovrascrive all'occorrenza la condizione di visualizzazione
	//del bottone di add
	//la necessità è nata perchè serviva una condizione che nascondesse il bottone di add
	// ma non i bottoni per rimuovere le chips (come disabled e readonly)
	//esempio: quando il chip selector raggiunge il limite massimo di valori
	// e il tipo di limite è BLOCK
	protected showAddButton: boolean = true;

	@Input('value')
	set value(val: ChipValue[] | undefined) {
		this.items.next(val ?? []);
	}
	@Input() emit: 'object' | 'uuid' = 'object';
	@Input() label: string = '';
	@Input() requiredIcon?: boolean = false;
	@Input() readonly: boolean = false;
	@Input() hint?: string;
	@Input() selectionLimit?: Limit;
	@Input() heightAuto?: boolean;

	@Output() changed = new EventEmitter<ChipValue[] | string[]>();

	protected onChangeFn: (value: ChipValue[] | string[]) => void = () => {};
	protected onTouchedFn: () => void = () => {};

	constructor() {}

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		// console.log('validate', c);
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		return c.invalid ? { ...c.errors } : null;
	}

	writeValue(val: ChipValue[]): void {
		// console.log('val', val);
		this.items.next(val);
	}

	registerOnChange(fn: (val: ChipValue[] | string[]) => void): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFn = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	protected valueChanged() {
		// console.log('this.items', this.items);
		this.changed.emit(this.getOutputValue());
		this.onChangeFn(this.getOutputValue());
		this.onTouchedFn();
	}

	protected clear() {
		this.items.next([]);
		this.valueChanged();
	}

	protected addValues(values: ChipValue[]) {
		let itemValue = this.items.value;
		const newValues = values.filter((val) => !itemValue?.some((item) => item.uuid === val.uuid));
		if (itemValue) itemValue = [...itemValue, ...newValues];
		else itemValue = newValues;

		this.items.next(itemValue);

		this.valueChanged();
	}

	protected removeValues(uuids: string[]) {
		let itemValue = this.items.value;
		itemValue = itemValue?.filter(({ uuid }) => !uuids.includes(uuid));

		this.items.next(itemValue);
		this.valueChanged();
	}

	protected setValues(values: ChipValue[]) {
		this.items.next(values);
		this.valueChanged();
	}

	getOutputValue() {
		const itemValue = this.items.value;
		if (this.emit === 'uuid') return itemValue?.map(({ uuid }) => uuid) ?? [];
		return itemValue ?? [];
	}

	/** Apre il dialogo di selezione nuovi elementi e deve restituire gli elementi selezionati formattati correttamente */
	abstract openDialog(currentValues: ChipValue[]): Observable<{ selected: ChipValue[]; replace?: boolean } | ChipValue[] | undefined>;

	add() {
		this.openDialog(this.items.value ?? [])
			.pipe(first())
			.subscribe((result) => {
				if (result) {
					if (!Array.isArray(result)) {
						if (result.replace === true) this.setValues(result.selected);
						else this.addValues(result.selected);
					} else {
						this.setValues(result);
					}
				}
			});
	}

	removeItem(item: ChipValue) {
		if (this.disabled) return;
		this.removeValues([item.uuid]);
	}
}

export interface ChipValue {
	uuid: string;
	name: { language: string; value: string }[] | string;
	order?: number;
	addiotionalProperties?: Record<string, unknown>;
	label?: string;
}
