import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, inject } from '@angular/core';
import { OperationPermissionFilterBody, OperationPermissionService } from '../../services/operation-permission.service';
import { BehaviorSubject, combineLatest, filter, map, shareReplay } from 'rxjs';
import {
	ApplicationUserGroup,
	ApplicationUserSmartGroup,
	FlattenedOperationPermission,
	OperationPermission,
	OperationPermissionEnum,
} from '../../models';
import { DataleanBaseApiService, ListComponent, LocalizableField, Parts, SharedModule } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfigurationService } from '../../services/configuration.service';

interface DataTableStructure {
	header: string[]; //lista delle OP da mostrare
	body: DataTableRoWBody[]; //"gruppo1UUID": {"shared": true, "download": "indeterminate"}
}
export interface DataTableRoWBody {
	groupUUID: string;
	groupName: string;
	permissions: Record<string, boolean | 'indeterminate'>;
}

export interface MatrixOperationResult {
	tableGroup: DataTableRoWBody[];
	tabelSmartGroup: DataTableRoWBody[];
	filters?: { [p: string]: string };
}
@Component({
	selector: 'datalean-matrix-operation-permission',
	templateUrl: './matrix-operation-permission.component.html',
	styleUrls: ['./matrix-operation-permission.component.scss'],
	standalone: true,
	imports: [CommonModule, ListComponent, MatTabsModule, TranslateModule, LottieModule, MatCheckboxModule, SharedModule],
})
export class MatrxOperationPermissionComponent {
	public dialogRef = inject(DialogRef);
	private operationPermissionService = inject(OperationPermissionService);
	private dataleanApi = inject(DataleanBaseApiService);
	private configurationService = inject(ConfigurationService);

	//operationPermission recuperate da servizio
	operationPermissions$ = new BehaviorSubject<OperationPermission[]>([]);
	//operationPermission espanse
	flattenedOperationPermissions$ = this.operationPermissions$.pipe(
		map((permissions) => {
			const result: FlattenedOperationPermission[] = [];

			//appiattisco le operation permission
			for (const permission of permissions) {
				for (const op of permission.operationList) {
					result.push({
						entityType: permission.entityType,
						entityUUID: permission.entityUUID,
						subjectType: permission.subjectType,
						subjectUUID: permission.subjectUUID,
						permission: op,
					});
				}
			}

			return result;
		}),
	);

	groups$ = this.dataleanApi.getEntities<ApplicationUserGroup[]>(environment.groupsUrl, undefined, [Parts.EMPTY]);

	smartGroups$ = this.dataleanApi.getEntities<ApplicationUserSmartGroup[]>(environment.smartGroupsUrl, undefined, [Parts.EMPTY]);

	dataTableGroup: { items: DataTableRoWBody[][]; pages?: number[]; totalItems: number } = {
		items: [],
		pages: [0],
		totalItems: 0,
	};

	dataTableSmartGroup: { items: DataTableRoWBody[][]; pages?: number[]; totalItems: number } = {
		items: [],
		pages: [0],
		totalItems: 0,
	};

	// possiblePermission = ['downloads', 'share'], sono le possibili OP asssegnabili (tramite config)
	//se non hai la config non vedrai nulla (attenzione attenersi all'enum BE "OperationPermissionEnum")
	possiblePermission$ = this.configurationService.isReady.pipe(
		filter((isReady) => isReady),
		map(() => {
			const permissions = this.configurationService.getConfigValue('operationPermissionOptions');
			if (typeof permissions !== 'string' && Array.isArray(permissions)) {
				return (permissions as { key: string; label: LocalizableField[] }[]).filter(
					//mostro solo gli operation permission che abbiamo davvero a modello
					(permission) => Object.values(OperationPermissionEnum).find((el) => el === permission.key),
				);
			}
			return [];
		}),
		shareReplay({ refCount: true, bufferSize: 1 }),
	);

	assetsCount: number = 0;
	tabIndex = 0;
	isLoading: boolean = true;

	constructor(
		@Inject(DIALOG_DATA)
		public data: {
			assetsUUIDs: string[];
			filters?: { [p: string]: string };
			selectionMode?: 'inclusive' | 'exclusive';
		},
	) {
		if (!data.selectionMode) {
			data.selectionMode = 'inclusive';
		}

		const body: OperationPermissionFilterBody = {
			assetsUUIDs: data.assetsUUIDs,
			assetsUUIDsMode: data.selectionMode,
			entityFilter: structuredClone(data.filters),
		};

		//chiamo il servizio con i filtri applicati
		this.operationPermissionService.fetchOperationPermissionWithCounter(body).subscribe((response) => {
			if (response) {
				this.assetsCount = response.assetsCount;
				this.operationPermissions$.next(response.operationPermissions);
			}
		});

		combineLatest([this.groups$, this.smartGroups$, this.flattenedOperationPermissions$, this.possiblePermission$])
			.pipe(
				takeUntilDestroyed(),
				map(([groups, smartGroups, flattenedOperationPermissions, possiblePermission]) => {
					const operationPermissions = possiblePermission.map((el) => el.key);
					return [
						this.calculateDataTable(groups, flattenedOperationPermissions, operationPermissions),
						this.calculateDataTable(smartGroups, flattenedOperationPermissions, operationPermissions),
					];
				}),
			)
			.subscribe(([dataGroup, dataSmartGroup]) => {
				this.dataTableGroup = { items: [dataGroup.body], pages: [0], totalItems: dataGroup.body.length };
				this.dataTableSmartGroup = { items: [dataSmartGroup.body], pages: [0], totalItems: dataSmartGroup.body.length };
			});
	}

	confirm() {
		if (this.dataTableSmartGroup.items.length > 0 && this.dataTableGroup.items.length > 0) {
			const result: MatrixOperationResult = {
				filters: this.data.filters,
				tabelSmartGroup: this.dataTableSmartGroup.items[0],
				tableGroup: this.dataTableGroup.items[0],
			};
			this.dialogRef.close(result);
		}
	}

	calculateDataTable(groups: ApplicationUserGroup[], flattenedOperationPermissions: FlattenedOperationPermission[], columnPermission: string[]) {
		const result: DataTableStructure = {
			header: columnPermission, //tutti i possibili permessi da settare (sono le colonne)
			body: [],
		};

		//creo la struttura, dove se due OP sono discordanti per lo stsso gruppo, il valore della sua checkbox sarà "indeterminate" che
		//equivale a dire che non è uno stato determinato (UI visibile)
		for (const group of groups) {
			const groupObject: DataTableRoWBody = { groupUUID: group.uuid, groupName: group.name, permissions: {} };

			//recupero gli operationPermissions del gruppo
			const groupPermission = flattenedOperationPermissions.filter((el) => el.subjectUUID === group.uuid);

			for (const op of result.header) {
				//prendo tutti gli OP del permesso che sto considerando
				const groupPermissionFilteredByOP = groupPermission.filter((el) => el.permission === op);

				//se non ho trovato neanche un OP per il permesso significa che tutti gli asset (selezionati)
				//non hanno quel permesso
				if (groupPermissionFilteredByOP.length === 0) groupObject.permissions[op] = false;
				//se ho esattamente tanti flattenedOp (del permesso che sto considerando) quanti asset
				//significa che tutti gli asset hanno il permesso
				else if (this.assetsCount === groupPermissionFilteredByOP.length) groupObject.permissions[op] = true;
				else groupObject.permissions[op] = 'indeterminate';
			}

			result.body.push(groupObject);
		}

		return result;
	}

	forceDetection(index: number) {
		this.tabIndex = index;
	}
}
