import { Component, Input } from '@angular/core';
import { PaginationInfo, TableRow } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { ApplicationUser } from '../../models';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';

@Component({
	selector: 'datalean-app-user-selector-dialog',
	templateUrl: './app-user-selector-dialog.component.html',
	styleUrls: ['./app-user-selector-dialog.component.scss'],
})
export class AppUserSelectorDialogComponent extends GenericSelectorDialogComponent<Partial<ApplicationUser>> {
	@Input() override selectedUUIDs: string[] = [];
	@Input() override searchFields: string = 'firstName,lastName,email';
	@Input() override searchParamName: string = 'q';

	override endpoint: string = environment.applicationUsersUrl;

	selectedRows?: TableRow[];

	constructor() {
		super();
		this.pageSize = 200;
		this.currentPage$.next([new PaginationInfo(this.pageSize, 0)]);
		console.log('AppUserSelectorDialogComponent -> constructor -> this.pageSize', this.gcs.totalSelected);
	}

	override mapData(data: ApplicationUser[]): TableRow[] {
		const result: TableRow[] = [];
		for (const item of data) {
			result.push({
				uuid: item.uuid,
				name: item.firstName + ' ' + item.lastName,
				email: item.email,
			});
		}
		return result;
	}
}
