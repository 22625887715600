import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DataleanTableColumn, Limit, PageChangeEvent, PaginationInfo, SearchComponent, TableRow } from 'addiction-components';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { AdditionalTableField, EntityType } from '../../models';
import { DataleanTableComponent } from '../datalean-table/datalean-table.component';
import { EntitySelectorDialogInfinityComponent } from '../entity-selector-dialog-infinity/entity-selector-dialog-infinity.component';

@Component({
	selector: 'datalean-generic-selector-dialog',
	templateUrl: './generic-selector-dialog.component.html',
	styleUrls: ['./generic-selector-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule, SearchComponent, MatTabsModule, MatCheckboxModule, LottieModule, DataleanTableComponent],
})
export class GenericSelectorDialogComponent<T extends Record<string, unknown>> extends EntitySelectorDialogInfinityComponent<T> implements OnInit {
	readonly env = environment;

	@Input() override searchFields: string = 'name';
	@Input() override searchParamName: string = 'q';
	@Input() override fieldsToShow: string[] = ['name'];
	@Input() override additionalFields?: AdditionalTableField<T>[] = [];

	@Input() entityUUID: string = '';
	@Input() entityType: EntityType = EntityType.FEATURE_VALUE;
	//settare a true se serve che oltre a application group e smart group sempre presenti
	//vengano visualizzati anche gli utenti applicativi
	@Input() showUserApplication?: boolean = false;
	@Input() selectionLimit?: Limit;

	protected translatePipe = inject(TranslatePipe);

	public columns: Array<DataleanTableColumn> = [
		{
			columnDef: 'check',
			header: '',
			thClass: 'checkbox',
			tdClass: 'checkbox',
			template: 0,
		},
		{
			columnDef: 'name',
			header: 'LIST_HEADER.NAME',
			thClass: 'double',
			tdClass: 'double',
			template: 1,
		},
	];

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.gcs.limit = this.selectionLimit;

		if (!this.additionalFields?.length) return;

		for (const additionalField of this.additionalFields) {
			this.columns.push({
				columnDef: additionalField.fieldName as string,
				header: this.translatePipe.transform(additionalField.columnHeader),
				template: additionalField.cellTemplate,
			});
		}
	}

	override mapData(data: T[]): TableRow[] {
		const result: TableRow[] = [];

		for (const item of data) {
			result.push({
				uuid: item['uuid'],
				name: `${item['name']}`,
				...(this.additionalFields?.length
					? this.additionalFields.reduce((acc, tableField) => ({ ...acc, [tableField.fieldName]: item[tableField.fieldName] }), {})
					: {}),
			});
		}

		// console.log('result', result);

		return result;
	}

	onPageChanged(page: PageChangeEvent) {
		this.currentPage$.next([new PaginationInfo(page.pageSize, page.offset)]);
	}

	// override close() {
	// 	this.closeDialog({
	// 		selected: Object.entries(this.gcs.checkboxMap).reduce((acc, [key, value]) => {
	// 			if (value) {
	// 				acc.push(key);
	// 			}
	// 			return acc;
	// 		}, new Array<string>()),
	// 	});
	// }

	onSelectionChange(event: MatCheckboxChange, entityUUID?: string): void {
		const checked = event.checked;
		if (entityUUID) {
			if (entityUUID === 'all') {
				for (const uuid of this._currentPageUUIDs) {
					this.gcs.setCheckboxMap(uuid, !!checked);
				}
			} else {
				// this.gcs.reset();
				this.gcs.setCheckboxMap(entityUUID, !!checked);
				if (!checked) {
					if (this.gcs.selectAllChecked) {
						for (const uuid of this._currentPageUUIDs.filter((uuid) => uuid !== entityUUID)) {
							this.gcs.setCheckboxMap(uuid, true);
						}
					}
					this.gcs.selectAllChecked = false;
				}
				this.gcs.updateSelectAllStatus();
			}
			event.source.checked = this.gcs.checkboxMap[entityUUID];
		}
		// console.log('this.gcs.checkboxMap', this.gcs.checkboxMap);
		// console.log('this.selectedUUIDs', this.selectedUUIDs);
		// console.log('this.unselectedUUIDs', this.unselectedUUIDs);
		// console.log('this.gcs.selectAllChecked', this.gcs.selectAllChecked);
		this.selectionChanged(
			Object.entries(this.gcs.checkboxMap).reduce((acc, [uuid, value]) => {
				if (value) {
					acc.push({ uuid });
				}
				return acc;
			}, new Array<TableRow>()),
		);
	}

	onCheckboxHeaderSelected(checked: boolean): void {
		for (const uuid of this._currentPageUUIDs) {
			this.gcs.setCheckboxMap(uuid, checked);
		}
	}

	public isItemChecked(uuid: string): boolean {
		return this.gcs.checkboxMap[uuid] === undefined ? this.gcs.selectAllChecked : this.gcs.checkboxMap[uuid];
	}
}
