import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take } from 'rxjs';
import { UserSelector } from 'src/app/core/state/app.selectors';
import { IPermission } from '../models';

@Injectable({ providedIn: 'root' })
export class PermissionService {
	store = inject(Store);
	router = inject(Router);

	constructor() {}

	checkUserPermission(requiredPermission: string): Observable<boolean> {
		return this.store.select(UserSelector.selectPermissions).pipe(
			filter((data) => data.length > 0),
			map((permissions: IPermission[]) => permissions.some((permission) => permission.name === requiredPermission)),
		);
	}
}
